import axios from 'axios'
import store from '../../index'

export default {
    
    async getSettings({commit}) {
        return new Promise((resolve, reject) => {
            axios({url: `/settings`, method: 'GET'})
                .then(resp => {
                    localStorage.setItem('settings', JSON.stringify(resp.data))
                    commit('update_settings', resp.data)
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async getStats({commit}) {
        return new Promise((resolve, reject) => {
            axios({url: `/dashboard/home/stats`, method: 'GET'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}
