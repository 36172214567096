<template>
  <v-container :style="{ maxWidth: fullWidth ? '100%' : '' }">
    <v-row>
      <v-col
          cols="12"
          sm="6"
          md="4"
          v-for="(plan, index) in this.pricingPlans"
          :key="index"
          ref="statCards"
      >
        <v-card :elevation="5">
          <div class="primary white--text d-flex justify-center pa-3 font-weight-medium" v-if="plan.priority">{{ $t('texts.home.pricing.mostly_chosen') }}</div>
          <v-card-text
              class="text--primary"
              :class="{'mt-12': !$vuetify.breakpoint.smAndDown && !plan.priority}"
          >
            <p class="mb-1 font-weight-bold text-subtitle-1 text-center text-md-left text-sm-left">
              {{ plan.title }}
            </p>
            <p class="text-center text-md-left text-sm-left">{{ plan.subtitle }}</p>
            <span class="pricing-spacer d-flex"></span>
            <v-card-title class="pa-0 mb-4 mt-4 text-h5 font-weight-bold align-baseline justify-center justify-md-start justify-sm-start">
              {{ plan.price }}€<span class="text-subtitle-1 font-weight-bold">/{{ plan.period }}</span>
            </v-card-title>
            <v-btn
                elevation="5"
                color="primary"
                class="mb-5"
                block
                @click="$emit('open-register-modal', plan)"
            >
              <span>{{ $t('texts.home.pricing.register_book_now') }}</span>
            </v-btn>
            <span class="pricing-spacer d-flex"></span>
            <div class="mt-5">
              <v-row
                  v-for="(feature, index) in plan.features"
                  :key="index"
                  align="center"
                  class="mb-2 mt-0 flex-column flex-md-row flex-sm-row"
              >
                <v-col cols="auto" class="pt-0 pb-0 pl-md-3 pr-md-2 pl-sm-3 pr-sm-2">
                  <v-icon color="primary" size="24px">mdi-check</v-icon>
                </v-col>
                <v-col class="pl-3 pr-3 pt-0 pb-0 pa-md-0 pa-sm-0 text-center text-md-left text-sm-left">
                  <span>{{ feature }}</span>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pricingPlans: [
        {
          title: this.$t('texts.home.pricing.plans.family_carefree.title'),
          subtitle: this.$t('texts.home.pricing.plans.family_carefree.subtitle'),
          price: '50',
          period: this.$t('texts.home.pricing.periods.yearly'),
          shopUrl: 'https://shop.geras24.de/kasse/?add-to-cart=101',
          features: [
            `${this.$t('texts.home.pricing.features.storage')}: 2000 MB`,
            this.$t('texts.home.pricing.features.checklist'),
            this.$t('texts.home.pricing.features.last_wish'),
            this.$t('texts.home.pricing.features.personal_message'),
            this.$t('texts.home.pricing.features.personal_documents'),
            this.$t('texts.home.pricing.features.document_templates'),
            this.$t('texts.home.pricing.features.vault'),
            this.$t('texts.home.pricing.features.up'),
          ]
        },
        {
          title: this.$t('texts.home.pricing.plans.family_carefree.title'),
          subtitle: this.$t('texts.home.pricing.plans.family_carefree.subtitle'),
          price: '4,35',
          priority: true,
          period: this.$t('texts.home.pricing.periods.monthly'),
          shopUrl: 'https://shop.geras24.de/kasse/?add-to-cart=5700',
          features: [
            this.$t('texts.home.pricing.features.seven_days_trial'),
            `${this.$t('texts.home.pricing.features.storage')}: 2000 MB`,
            this.$t('texts.home.pricing.features.checklist'),
            this.$t('texts.home.pricing.features.last_wish'),
            this.$t('texts.home.pricing.features.personal_message'),
            this.$t('texts.home.pricing.features.personal_documents'),
            this.$t('texts.home.pricing.features.document_templates'),
            this.$t('texts.home.pricing.features.vault'),
            this.$t('texts.home.pricing.features.up'),
          ]
        },
        {
          title: this.$t('texts.home.pricing.plans.lifetime.title'),
          subtitle: this.$t('texts.home.pricing.plans.lifetime.subtitle'),
          price: '850',
          period: this.$t('texts.home.pricing.periods.lifetime'),
          shopUrl: 'https://shop.geras24.de/kasse/?add-to-cart=102',
          features: [
            `${this.$t('texts.home.pricing.features.storage')}: 5000 MB`,
            this.$t('texts.home.pricing.features.checklist'),
            this.$t('texts.home.pricing.features.last_wish'),
            this.$t('texts.home.pricing.features.personal_message'),
            this.$t('texts.home.pricing.features.personal_documents'),
            this.$t('texts.home.pricing.features.document_templates'),
            this.$t('texts.home.pricing.features.vault'),
            this.$t('texts.home.pricing.features.up'),
          ]
        }
      ],
    };
  },
};
</script>

<style scoped>
.pricing-spacer {
  height: 1px;
  width: 100%;
  background-color: rgba(0, 0, 0, .87);
}
</style>
