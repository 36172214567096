import axios from 'axios'

export default {

    async getPays({ commit }) {
        return new Promise((resolve, reject) => {
            axios({ url: '/dashboard/pays', method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async getPlans({ commit }) {
        return new Promise((resolve, reject) => {
            axios({ url: `/plans`, method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async redeemGivenCode({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios({ url: `/plans/subscribe/redeem-given-code`, data: data, method: 'POST' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}
