const mutations = {
    auth_request(state) {
        state.status = 'loading'
    },
    auth_success(state, user) {
        state.status = 'success'
        state.token = user.token
        state.user = user
    },
    auth_error(state) {
        state.status = 'error'
    },
    logout(state) {
        state.status = ''
        state.token = ''
        state.tresorToken = ''
        state.user = {}
        localStorage.clear()
    },

    updateUser(state, user) {
        state.user.user = user
    },

    updateLocale(state, newLocale) {
        state.locale = newLocale
    },

    update_settings(state, settings) {
        state.settings = settings
    },

    SET_BAR_IMAGE(state, payload) {
        state.barImage = payload
    },
    SET_DRAWER(state, payload) {
        state.drawer = payload
    },
    update_user(state, user) {
        state.user.user = user
    },

    set_tresor_token(state, token) {
        state.tresorToken = token
    },

    clear_tresor_token(state) {
        state.tresorToken = ''
        localStorage.removeItem('tresorToken');
    },

    login_user_as_trusted_user(state, user_id) {
        state.userIsLoggedInAsTrustedUser = true
        state.originalUserID = user_id
        state.tresorToken = '123'
    },

    login_admin_as_user(state, user) {
        state.adminIsLoggedInAsUser = true
        state.adminRole = user.role
        state.originalUserID = user.id
    },

    clear_user_imitation(state) {
        state.userIsLoggedInAsTrustedUser = false
        state.adminIsLoggedInAsUser = false
        state.adminRole = null
        state.originalUserID = null
    }
}

export default mutations
