import axios from 'axios'

export default {
    async adminGetCompetitionParticipants({commit}, page) {
        return new Promise((resolve, reject) => {
            axios({url: `/admin/competition-participants?page=${page}`, method: 'GET'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminGetRandomCompetitionParticipant({commit}, competition) {
        return new Promise((resolve, reject) => {
            axios({url: `/admin/competition-participants/random/${competition}`, method: 'GET'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminDeleteCompetitionParticipant({commit}, id) {
        return new Promise((resolve, reject) => {
            axios({url: `/admin/competition-participants/${id}`, method: 'DELETE'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminBulkDeleteCompetitionParticipants({commit}, competition) {
        return new Promise((resolve, reject) => {
            axios({url: `/admin/competition-participants/bulk/${competition}`, method: 'DELETE'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}
