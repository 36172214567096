<template>
  <v-container>
    <v-row>
      <v-col
          cols="12"
          sm="6"
          md="4"
          v-for="(statistic, index) in statistics"
          :key="index"
          ref="statCards"
      >
        <v-card :elevation="5">
          <v-card-text class="text--primary text-center">
            <p class="mb-4 mt-4 font-weight-bold text-subtitle-1">
              {{ statistic.title }}
            </p>
            <span class="statistics-spacer d-flex primary"></span>
            <v-card-title class="justify-center pa-0 mb-4 mt-4 text-h5 font-weight-bold">
              +<span>{{ statistic.currentNumber }}</span>
            </v-card-title>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    statistics: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      observer: null,
      duration: 1500,
    };
  },
  methods: {
    startCounting(statistic) {
      const end = statistic.number;
      const steps = 60;
      const stepTime = Math.abs(Math.floor(this.duration / steps));
      const increment = end / steps;
      let current = 0;

      const timer = setInterval(() => {
        current += increment;

        if (current >= end) {
          this.$set(statistic, "currentNumber", end.toFixed(0));
          clearInterval(timer);
        } else {
          this.$set(statistic, "currentNumber", current.toFixed(0));
        }
      }, stepTime);
    },
    observeElements() {
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = entry.target.getAttribute("data-index");

            this.startCounting(this.statistics[index]);
            this.observer.unobserve(entry.target);
          }
        });
      });

      this.$refs.statCards.forEach((el, index) => {
        el.setAttribute("data-index", index);

        this.observer.observe(el);
      });
    },
  },
  mounted() {
    this.statistics.forEach((statistic) => {
      this.$set(statistic, "currentNumber", 0);
    });

    this.observeElements();
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
};
</script>

<style scoped>
.statistics-spacer {
  width: 3px;
  height: 22px;
  margin: auto;
}
</style>
