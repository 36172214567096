<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="3">
        <v-btn
            v-for="(point, index) in points"
            :key="index"
            @click="selectItem(index)"
            block
            large
            :color="index === activeIndex ? 'primary' : 'white'"
            class="usage-point-btn mb-5"
        >
          <span v-if="index === activeIndex">{{ point.title }}</span>
          <span v-else>{{ $t('texts.home.usage_points.default_button_text') }}</span>
        </v-btn>
      </v-col>
      <v-col cols="12" md="9">
        <v-card v-if="activeItem">
          <v-card-text class="text--primary">
            <v-row>
              <v-col cols="12" md="6">
                <v-img :src="activeItem.image" :alt="activeItem.title" contain></v-img>
              </v-col>
              <v-col cols="12" md="6" class="text-center text-md-left">
                <v-card-title class="pa-0 mb-2 font-weight-bold text-subtitle-1 justify-center justify-md-start">{{ activeItem.title }}</v-card-title>
                <p>{{ activeItem.description }}</p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    points: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      activeIndex: 0,
    };
  },
  computed: {
    activeItem() {
      return this.points[this.activeIndex];
    },
  },
  methods: {
    selectItem(index) {
      this.activeIndex = index;
    },
  },
};
</script>

<style scoped>
.usage-point-btn {
  height: 60px !important;
}
</style>
