<template>
  <v-container>
    <v-row>
      <v-col>
        <h2 class="text-center mb-3">{{ title }}</h2>
        <p class="text-center" v-if="subtitle">{{ subtitle }}</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
    }
  },
};
</script>
