<template>
  <v-row justify="center">
    <v-dialog
        :value="value"
        @input="$emit('input', $event)"
        persistent
        width="500"
    >
      <v-card>
        <v-card-title :class="{'mb-5': !isOtp}">
          <span v-if="isOtp">{{ $t('otp_title') }}</span>
          <span v-else>{{ $t('login') }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="$emit('close-login-modal')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="text--primary">
          <v-container v-if="isOtp">
            <v-row class="mb-6">
              {{ $t('otp_description') }}
            </v-row>
            <v-row>
              <v-text-field
                  ref="otpInput"
                  :label="$t('otp_code')"
                  :disabled="loading"
                  v-model="otpForm.otp"
                  autocomplete="one-time-code"
                  @keyup.enter="verify"
                  outlined
              ></v-text-field>
            </v-row>
            <v-row>
              <v-btn
                  :loading="loading"
                  :disabled="loading"
                  color="primary"
                  block
                  large
                  @click="verify"
              >
                {{ $t('otp_confirm') }}
              </v-btn>
            </v-row>
          </v-container>
          <v-container v-else>
            <v-row>
              <v-text-field
                  :label="$t('email')"
                  :disabled="loading"
                  v-model="loginForm.username"
                  outlined
                  @keyup.enter="login"
              ></v-text-field>
            </v-row>
            <v-row class="mt-1">
              <v-text-field
                  :label="$t('password')"
                  :type="showPassword ? 'text' : 'password'"
                  :disabled="loading"
                  outlined
                  v-model="loginForm.password"
                  @keyup.enter="login"
                  :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                  @click:append="togglePasswordVisibility"
              ></v-text-field>
            </v-row>
            <v-row>
              <v-btn
                  :loading="loading"
                  :disabled="loading"
                  color="primary"
                  block
                  large
                  @click="login"
              >
                {{ $t('login') }}
              </v-btn>
            </v-row>
            <v-row v-if="showVerificationLink" class="mt-8">
              <v-card class="pa-3 text--primary">{{ $t('login_not_confirmed') }}</v-card>
            </v-row>
            <v-row class="pt-4">
              <v-col class="text-center">
                <p>{{ $t('no_account') }} <a @click="closeAndOpenRegisterModal()" class="primary--text text-decoration-none">{{ $t('create_account_for_free') }}</a></p>
                <p class="ma-0">
                  <a @click="closeAndOpenPasswordResetModal()" class="primary--text text-decoration-none">{{ $t('forget_password') }}</a>
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import PasswordResetDialog from "@/dialogs/PasswordResetDialog.vue";

export default {
  components: {PasswordResetDialog},
  props: ['value'],
  data() {
    return {
      loading: false,
      isOtp: false,
      token: null,
      showPassword: false,
      loginForm: {
        username: null,
        password: null,
      },
      otpForm: {
        otp: null
      },
      showVerificationLink: false
    }
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    login() {
      if (!this.loginFormValid()) {
        return;
      }

      this.loading = true

      this.$store.dispatch('login', this.loginForm)
          .then(resp => {
            if (resp.result === false) {
              this.showVerificationLink = true
              this.loading = false
            } else {
              this.showVerificationLink = false

              this.loginForm = {
                username: null,
                password: null,
              }

              this.action = resp.action || null

              if (this.action === 'REQUIRE_OTP') {
                this.loading = false
                this.token = resp.token || null;
                this.isOtp = true

                this.$nextTick(() => {
                  this.$refs.otpInput.focus();
                });
              } else {
                this.$helpers.showMessage('success', this.$t('success_login'))
                this.redirectAfterLogin()
              }
            }
          })
          .catch(err => {
            this.loading = false
            this.$helpers.showError(err)
          })
    },
    verify() {
      if (!this.token) {
        location.reload()
        return;
      }

      if (!this.otpFormValid()) {
        return;
      }

      this.loading = true

      this.$store.dispatch('loginOtp', {
        otp: this.otpForm.otp,
        token: this.token
      })
          .then(() => {
            this.$helpers.showMessage('success', this.$t('success_login'))
            this.redirectAfterLogin()
          })
          .catch(err => {
            this.$helpers.showError(err)
            this.loading = false
          })
    },
    loginFormValid() {
      if (!this.loginForm.username) {
        this.$helpers.showMessage('error', this.$t('enter_email'))
        return false;
      }

      if (!this.loginForm.password) {
        this.$helpers.showMessage('error', this.$t('enter_password'))
        return false;
      }

      return true;
    },
    otpFormValid() {
      if (!this.otpForm.otp) {
        this.$helpers.showMessage('error', this.$t('enter_otp'))
        return false;
      }

      return true;
    },
    redirectAfterLogin() {
      if (this.$route.meta.preventRedirectAfterLogin) {
        location.reload()
      } else if (this.$route.query.redirect) {
        window.location = this.$route.query.redirect
      } else if (this.$route.params.id) {
        this.$router.push({name: 'profilePlans', params: {id: this.$route.params.id}})
      } else {
        this.$router.push({name: 'profile'})
      }
    },
    closeAndOpenRegisterModal() {
      this.$emit('close-login-modal')
      this.$emit('open-register-modal')
    },
    closeAndOpenPasswordResetModal() {
      this.$emit('close-login-modal')
      this.$emit('open-password-reset-modal')
    }
  }
}

</script>
