import axios from 'axios'
import store from '../../index'

export default {

    async getDocuments({commit}, data) {
        return new Promise((resolve, reject) => {
            axios({url: `/dashboard/documents/${data.collection}${data.category ? ('/' + data.category) : ''}`, method: 'GET'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async deleteDocument({commit}, id) {
        return new Promise((resolve, reject) => {
            axios({url: `/dashboard/documents/${id}`, method: 'DELETE'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    }
}
