import axios from 'axios'
import store from '../../index'

export default {

    async getPersonaldocsFiles({commit}, category) {
        return new Promise((resolve, reject) => {
            axios({url: `/dashboard/personalDocs/${category}`, method: 'GET'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async deletePersonaldocsFile({commit}, data) {
        return new Promise((resolve, reject) => {
            axios({url: `/dashboard/personalDocs/${data.id}`, method: 'DELETE'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async getPersonaldocsFile({commit}, slug) {
        return new Promise((resolve, reject) => {
            axios({url: `/dashboard/files/${slug}`, method: 'GET'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async uploadPersonaldocsFile({commit}, data) {
        return new Promise((resolve, reject) => {
            axios({url: `/dashboard/personalDocs`, data: data.data, method: 'POST'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}
