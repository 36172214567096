import axios from 'axios'
import store from '../../index'

export default {

    async getCategories({commit}, type) {
        return new Promise((resolve, reject) => {
            axios({
                url: `/dashboard/fileCategories/${type}${(type == 'vault' || type == 'personaldocs' || type == 'advisor') ? '' : '/wunsch'}`,
                method: 'GET'
            })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async storeCategories({commit}, data) {
        return new Promise((resolve, reject) => {
            axios({
                url: `/dashboard/fileCategories/${data.type}${(data.type == 'vault' || data.type == 'personaldocs' || data.type == 'advisor') ? '' : '/wunsch'}`,
                data: data,
                method: 'POST'
            })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async updateCategories({commit}, data) {
        return new Promise((resolve, reject) => {
            axios({url: `/dashboard/fileCategories/${data.id}`, data: data, method: 'PUT'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async deleteCategories({commit}, data) {
        return new Promise((resolve, reject) => {
            axios({
                url: `/dashboard/fileCategories/${data.id}${data.type == 'vault' ? `?verify_key=${data.verifyKey}` : ''}`,
                method: 'DELETE'
            })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}
