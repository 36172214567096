import axios from 'axios'
import store from '../../index'

export default {

    async getMedias({commit}, data) {
        return new Promise((resolve, reject) => {
            axios({url: `/dashboard/medias/${data.collection}/${data.type}${data.category ? ('/' + data.category) : ''}`, method: 'GET'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async deleteMedia({commit}, id) {
        return new Promise((resolve, reject) => {
            axios({url: `/dashboard/medias/${id}`, method: 'DELETE'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async getImage({commit}, link) {
        return new Promise((resolve, reject) => {
            axios({url: `/dashboard/files/${link}`, method: 'GET'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}
