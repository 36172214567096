<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="6" md="4" v-for="(feature, index) in features" :key="index">
        <v-hover v-slot="{ hover }">
          <v-card
             height="100%"
              :elevation="hover ? 7 : 5"
              :href="feature.target"
              :target="feature.target.includes('http') ? '_blank' : ''"
          >
            <v-img
                :src="feature.image"
                :alt="feature.title"
                :max-height="240"
                :contain="true"
            >
            </v-img>
            <v-card-text class="text--primary text-center">
              <v-card-title class="mb-2 pa-0 justify-center font-weight-bold">{{ feature.title }}</v-card-title>
              <p>{{ feature.description }}</p>
            </v-card-text>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    features: {
      type: Array,
      required: true,
    },
  },
};
</script>
