import axios from 'axios'

export default {

    async adminGetAdmins({commit}, page) {
        return new Promise((resolve, reject) => {
            axios({url: `/admin/users/admin`, method: 'GET'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminGetAdmin({commit}, id) {
        return new Promise((resolve, reject) => {
            axios({url: `/admin/users/admin/${id}`, method: 'GET'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminSetAdmin({commit}, data) {
        return new Promise((resolve, reject) => {
            axios({url: `/admin/users/admin`, data: data, method: 'POST'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminUpdateAdmin({commit}, data) {
        return new Promise((resolve, reject) => {
            axios({url: `/admin/users/admin/${data.id}`, data: data, method: 'PUT'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminDeleteAdmin({commit}, id) {
        return new Promise((resolve, reject) => {
            axios({url: `/admin/users/admin/${id}`, method: 'DELETE'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}
