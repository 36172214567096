import axios from 'axios'

export default {

    async getTrustedUsers({commit}) {
        return new Promise((resolve, reject) => {
            axios({url: `/dashboard/trustedUsers`, method: 'GET'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async getEntrustedUsers({commit}) {
        return new Promise((resolve, reject) => {
            axios({url: `/dashboard/entrustedUsers`, method: 'GET'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async addTrustedUser({commit}, data) {
        return new Promise((resolve, reject) => {
            axios({url: `/dashboard/trustedUsers`, data: data, method: 'POST'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async resendEmailForTruster({commit}, id) {
        return new Promise((resolve, reject) => {
            axios({url: `/dashboard/trustedUsers/resendEmail/${id}`, method: 'GET'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async deleteTrustedUser({commit}, id) {
        return new Promise((resolve, reject) => {
            axios({url: `/dashboard/trustedUsers/remove/${id}`, method: 'DELETE'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}
