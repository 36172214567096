<template>
  <v-row justify="center">
    <v-dialog
        :value="value"
        @input="$emit('input', $event)"
        persistent
        width="500"
    >
      <v-card>
        <v-card-title>
          {{ getTitle() }}
          <v-spacer></v-spacer>
          <v-btn icon @click="$emit('close-register-modal');">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="text--primary">
          <v-container>
            <div v-if="!$store.getters.isLoggedIn">
              <v-row v-if="priceModel && priceModel.price !== 0" class="mb-6">
                <div class="font-weight-bold">{{ $t('register_book_selected') }} <span class="font-italic">{{ priceModel.title }} {{ priceModel.price }}€/{{ priceModel.period }}</span></div>
                <div class="mt-3">{{ $t('register_book_selected_description') }}</div>
              </v-row>
              <v-row v-else-if="competition" class="mb-6">
                {{ $t('register_compete_description') }}
              </v-row>
              <v-row v-else class="mb-6">
                {{ $t('register_description') }}
              </v-row>
              <v-row>
                <v-text-field
                    :label="$t('first_name')"
                    :disabled="loading"
                    outlined
                    v-model="form.firstname"
                ></v-text-field>
              </v-row>
              <v-row class="mt-1">
                <v-text-field
                    :label="$t('last_name')"
                    :disabled="loading"
                    outlined
                    v-model="form.name"
                ></v-text-field>
              </v-row>
              <v-row class="mt-1">
                <v-text-field
                    email
                    :label="$t('email')"
                    :disabled="loading"
                    outlined
                    v-model="form.email"
                ></v-text-field>
              </v-row>
              <v-row class="mt-1">
                <v-text-field
                    :label="$t('password')"
                    :type="showPassword ? 'text' : 'password'"
                    :disabled="loading"
                    outlined
                    v-model="form.password"
                    :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                    @click:append="togglePasswordVisibility"
                    @input="validatePassword"
                ></v-text-field>
              </v-row>
              <v-row v-if="form.password && !this.allPasswordRulesValid" class="mt-0 mb-4 password-rules">
                <v-col v-for="rule in passwordRules" :key="rule.text" cols="12" class="pa-0">
                  <v-icon :color="rule.isValid ? 'success' : 'error'" size="20">
                    {{ rule.isValid ? 'mdi-check' : 'mdi-close' }}
                  </v-icon>
                  <span :class="rule.isValid ? 'text-success' : 'text-error'">
                  {{ rule.text }}
                </span>
                </v-col>
              </v-row>
              <v-row class="mt-1">
                <v-text-field
                    :label="$t('password_confirm')"
                    :type="showPassword ? 'text' : 'password'"
                    :disabled="loading"
                    outlined
                    v-model="form.password_confirmation"
                    :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                    @click:append="togglePasswordVisibility"
                ></v-text-field>
              </v-row>
              <v-row class="mt-6 mb-8">
                <v-checkbox v-model="form.terms" class="register">
                  <template v-slot:label>
                    <div v-if="priceModel">
                      {{ $t('register_i_accept') }}
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <a
                              target="_blank"
                              class="dark"
                              href="/allgemeine-verkaufsbedingungen"
                              @click.stop
                              v-on="on"
                          >{{ $t('register_terms') }}</a>
                        </template>
                        {{ $t('register_go_to_terms') }}
                      </v-tooltip>
                      {{ $t('register_and') }}
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <a
                              target="_blank"
                              class="dark"
                              href="/datenschutz"
                              @click.stop
                              v-on="on"
                          >{{ $t('register_privacy') }}</a>
                        </template>
                        {{ $t('register_go_to_privacy') }}
                      </v-tooltip>
                      {{ $t('register_noted') }}
                    </div>
                    <div v-else>
                      {{ $t('register_i_accept') }}
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <a
                              target="_blank"
                              class="dark"
                              href="/datenschutz"
                              @click.stop
                              v-on="on"
                          >{{ $t('register_privacy') }}</a>
                        </template>
                        {{ $t('register_go_to_privacy') }}
                      </v-tooltip>
                      {{ $t('register_noted') }}
                    </div>
                  </template>
                </v-checkbox>
              </v-row>
              <v-row class="mt-2" justify="center">
                <v-btn
                    :loading="loading"
                    :disabled="loading"
                    color="primary"
                    block
                    large
                    @click="register()"
                >
                  {{ getButtonTitle() }}
                </v-btn>
              </v-row>
              <v-row class="pt-2" v-if="!priceModel && !competition">
                <v-col justify="center" align="center">
                  <p class="ma-0"><a @click="closeAndOpenLoginModal()" class="primary--text text-decoration-none">{{ $t('login') }}</a> {{ $t('with_your_account') }}</p>
                </v-col>
              </v-row>
            </div>
            <div v-else>
              <v-row v-if="priceModel && priceModel.price !== 0">
                <div class="font-weight-bold">{{ $t('register_book_selected') }} <span class="font-italic">{{ priceModel.title }} {{ priceModel.price }}€/{{ priceModel.period }}</span></div>
                <div class="mt-3">{{ $t('register_book_logged_in') }}</div>
              </v-row>
              <v-row class="mt-8" justify="center">
                <v-btn
                    :loading="loading"
                    :disabled="loading"
                    color="primary"
                    block
                    large
                    @click="redirectToShop"
                >
                  {{ getButtonTitle() }}
                </v-btn>
              </v-row>
            </div>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

export default {
  props: ['value', 'priceModel', 'competition'],
  data() {
    return {
      loading: false,
      showPassword: false,
      passwordRules: [
        {text: this.$t('password_rules.min_length'), isValid: false},
        {text: this.$t('password_rules.uppercase'), isValid: false},
        {text: this.$t('password_rules.lowercase'), isValid: false},
        {text: this.$t('password_rules.number'), isValid: false},
        {text: this.$t('password_rules.special'), isValid: false}
      ],
      form: {
        firstname: null,
        name: null,
        email: null,
        password: null,
        password_confirmation: null,
        terms: null,
        referred_by: this.$route.query.c || null
      },
    }
  },
  computed: {
    allPasswordRulesValid() {
      return this.passwordRules.every(rule => rule.isValid);
    }
  },
  methods: {
    getTitle() {
      if (this.priceModel) {
        return this.$t('register_book')
      } else if (this.competition) {
        return this.$t('competition_participation')
      } else {
        return this.$t('register')
      }
    },
    getButtonTitle() {
      if (this.priceModel) {
        return this.$t('register_go_to_shop')
      } else if (this.competition) {
        return this.$t('register_compete')
      } else {
        return this.$t('register')
      }
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    register() {
      if (!this.formValid()) {
        return
      }

      this.loading = true

      if (this.competition) {
        this.form.competition_id = this.competition
      }

      this.$store.dispatch('register', this.form)
          .then(() => {
            this.$emit('close-register-modal')
            this.$emit('register-success')
            this.$helpers.showMessage('success', this.$t('register_send_activation_email'))
            this.form = {}

            if (this.priceModel) {
              this.redirectToShop()
            } else {
              if (typeof fbq !== "undefined") {
                window.fbq('track', 'Lead')
              }

              this.loading = false
            }
          })
          .catch(err => {
            this.$helpers.showError(err)
            this.loading = false
          })
    },
    formValid() {
      if (!this.form.firstname) {
        this.$helpers.showMessage('error', this.$t('enter_firstname'))
        return false;
      }

      if (!this.form.name) {
        this.$helpers.showMessage('error', this.$t('enter_name'))
        return false;
      }

      if (!this.form.email) {
        this.$helpers.showMessage('error', this.$t('enter_email'))
        return false;
      }

      if (!this.validateEmail()) {
        this.$helpers.showMessage('error', this.$t('email_not_valid'))
        return false;
      }

      if (!this.form.password) {
        this.$helpers.showMessage('error', this.$t('enter_password'))
        return false;
      }

      if (!this.allPasswordRulesValid) {
        this.$helpers.showMessage('error', this.$t('password_not_valid'));
        return false;
      }

      if (!this.form.password_confirmation) {
        this.$helpers.showMessage('error', this.$t('enter_password_confirm'))
        return false;
      }

      if (this.form.password !== this.form.password_confirmation) {
        this.$helpers.showMessage('error', this.$t('password_confirm_error'))
        return false;
      }

      if (!this.form.terms) {
        this.$helpers.showMessage('error', this.$t('accept_terms'))
        return false;
      }

      return true;
    },
    validateEmail() {
      const email = this.form.email || '';
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      return emailPattern.test(email);
    },
    validatePassword() {
      const password = this.form.password || '';

      this.passwordRules[0].isValid = password.length >= 8; // Minimum 8 characters
      this.passwordRules[1].isValid = /[A-Z]/.test(password); // At least one uppercase letter
      this.passwordRules[2].isValid = /[a-z]/.test(password); // At least one lowercase letter
      this.passwordRules[3].isValid = /\d/.test(password);    // At least one number
      this.passwordRules[4].isValid = /[!@#$%^&*(),.?":{}|<>]/.test(password); // At least one special character
    },
    redirectToShop() {
      this.loading = true;

      setTimeout(() => {
        let redirect = window.open(this.priceModel.shopUrl, '_blank');

        if (!redirect || redirect.closed || typeof redirect.closed == 'undefined') {
          this.$emit('open-popup-blocked-modal')
        }

        this.loading = false;
      }, 1500)
    },
    closeAndOpenLoginModal() {
      this.$emit('close-register-modal')
      this.$emit('open-login-modal')
    },
  }
}
</script>
