import axios from 'axios'

export default {

    async adminGetPermissions({commit}) {
        return new Promise((resolve, reject) => {
            axios({url: `/admin/permissions`, method: 'GET'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminGetRoles({commit}) {
        return new Promise((resolve, reject) => {
            axios({url: `/admin/roles`, method: 'GET'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminGetRole({commit}, id) {
        return new Promise((resolve, reject) => {
            axios({url: `/admin/roles/${id}`, method: 'GET'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminSetRole({commit}, data) {
        return new Promise((resolve, reject) => {
            axios({url: `/admin/roles`, data: data, method: 'POST'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminUpdateRole({commit}, data) {
        return new Promise((resolve, reject) => {
            axios({url: `/admin/roles/${data.id}`, data: data, method: 'PUT'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminDeleteRole({commit}, id) {
        return new Promise((resolve, reject) => {
            axios({url: `/admin/roles/${id}`, method: 'DELETE'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}
