<template>
  <v-container class="pa-0">
    <v-row
        v-for="(topic, index) in topics"
        :key="index"
        align="center"
        class="mb-2 mt-0 flex-column flex-md-row"
    >
      <v-col cols="auto" class="pt-0 pb-0 pl-md-3 pr-md-2 pl-sm-3 pr-sm-2">
        <v-icon color="primary" size="30px">{{ computedIcon }}</v-icon>
      </v-col>
      <v-col class="pl-3 pr-3 pt-0 pb-0 pa-md-0 pa-sm-0 text-center text-md-left">
        <span v-html="topic"></span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    topics: {
      type: Array,
      required: true,
    },
    icon: {
      type: String,
      default: "mdi-check",
    },
    mobileIcon: {
      type: String,
      default: "mdi-check",
    },
  },
  computed: {
    computedIcon() {
      return this.$vuetify.breakpoint.smAndDown ? this.mobileIcon : this.icon;
    },
  },
};
</script>
