export default {
    new_admin: 'Neuer Admin',
    admins: 'Admins',
    edit_admin: 'Bearbeite Admin',

    role: 'Aufgabe',
    roles: 'Aufgaben',
    edit_role: 'Bearbeite Aufgabe',
    role_name: 'Aufgabenname',
    enter_role_name: 'Bitte gebe einen Aufgabennamen an!',
    select_permissions: 'Bitte wähle eine Zuständigkeit aus!',
    role_updated: 'Aufgabe aktualisiert',
    new_role: 'Neue Aufgabe',
    delete_role_alarm: 'Beim Löschen wird diese Aufgabe gelöscht, bist du sicher?',
    are_you_sure: 'Bist du sicher?',

    emails: 'E-Mails',
    competition_participants: 'Gewinnspiel-Teilnehmer',
    determine_winner: 'Gewinner bestimmen',
    determine_winner_explanation: 'Bitte wähle ein Gewinnspiel aus und klicke auf die nachfolgende Schaltfläche, um einen zufälligen Gewinner zu bestimmen. Es werden alle Teilnehmer des Gewinnspiels berücksichtigt, unabhängig von der aktuellen Seite.',
    delete_participants: 'Teilnehmer löschen',
    no_competition_participants: 'Bisher haben noch keine Benutzer an einem Gewinnspiel teilgenommen.',

    competition: 'Gewinnspiel',
    participated_at: 'Teilgenommen am',
    pays: 'Zahlungen',
    settings: 'Einstellungen',
    delete_entry_alert: "Nach dem Löschen ist der Eintrag unwiederbringlich verloren.",
    delete_competition_participants_alert: "Nach dem Löschen sind alle Teilnehmer des gewählten Gewinnspiels unwiederbringlich verloren. Zudem können bereits teilgenommene Nutzer wieder am Gewinnspiel teilnehmen.",
    yes: 'Ja',
    no: 'Nein',

    all_users: 'Alle Benutzer',
    some_user: 'Einige Benutzer',
    user: 'Benutzer',
    users: 'Benutzer',
    search_user: 'Benutzer suchen',

    enable: 'Aktivieren',
    disable: 'Deaktivieren',
    died: 'Tot',
    reffered: 'Zugewiesen',
    debt: 'Schuld',

    plans: 'Preismodell',
    all_plans: 'Alle Preismodelle',

    email_templates: 'E-Mail-Vorlage',
    new_template: 'Neue Vorlage',
    email_created: 'Neue E-Mail-Vorlage erstellt',
    template: 'Vorlage',
    send_to: 'Senden an',
    sended_emails: 'Gesendete E-Mails',
    new_email: 'Neue E-Mail',
    send_new_email: 'Neue E-Mail senden',
    send_email: 'Sende E-Mail',
    to: 'an',
    sended_at: 'Gesendet am',
    email_sended: 'E-Mail gesendet!',
    support_tickets: 'Support Anfrage',
    supports: 'Support',
    all: 'Alle',
    successful: 'Erledigt',
    accepts: 'Accepts',

    create_email: 'Neue E-Mail-Vorlage erstellen',
    attach_files: 'Anhang',
    select_attach: 'Wähle Anhang',
    file_name: 'Dateiname',
    create: 'Erstellen',

    all_success_pays: 'Jeder Erfolg zahlt sich aus',
    income: 'Einkommen',
    filter_from: 'Filter: Von',
    filter_to: 'Filter: Bis',

    ref_id: 'Referenz ID',
    payer_id: 'Zahlungs-ID',
    failed: 'Nicht bezahlt',
    success: 'Bezahlt',
    started: 'Beginn',

    edit_plan: 'Bearbeite Preismodell',
    plan_name: 'Name Preismodell',
    plan_desc: 'Preismodell Beschreibung',
    plan_dur: 'Laufzeit Preismodell (Tage)',
    plan_price: 'Preismodell Preis',
    plan_price_year: 'Preismodell Preis/Jahr',
    plan_price_month: 'Preismodell Preis/Monat',
    plan_currency: 'Preismodell Währung',
    new_plan: 'Neues Preismodell',
    create_new_plan: 'Erstelle neues Preismodell',
    plan_shop_link: 'Shop-Link',

    dur_days: 'Laufzeit (Tage)',

    account_information: 'Benutzerkonten Informationen',

    general: 'Allgemein',
    icons: 'Icons',
    general_settings: 'Allgemeine Einstellungen',
    site_title: 'Titel',
    site_subtitle: 'Seiten Untertitel',
    site_copyright: 'Seiten Copyright',
    about: 'Über uns',
    participation_conditions: 'Teilnahmebedingungen',
    impressum: 'Impressum',
    datenschutz: 'Datenschutz',

    link_url: 'Link Url',
    footer_logo1: 'Footer Logo 1',
    footer_logo2: 'Footer Logo 2',
    footer_logo3: 'Footer Logo 3',
    logged_home: 'Startseite Login-Bereich',

    done: 'Geschafft!',

    close_ticket: 'Erledige Supportanfrage',

    send_notif: 'Sende allen Nutzern eine Benachrichtigung',
    send_notif_to: 'Sende Benachrichtigung an',
    send: 'Senden',
    notif_sended: 'Benachrichtigung gesendet!',

    trash: 'Papierkorb',
    joined_at: "Registriert am",

    content: 'Inhalt',

    last_updated: 'Letzte Aktualisierung',

    checklist: 'Checkliste',
    new_item: 'Neuer Eintrag',
    new_checklist_item: 'Neuer Eintrag',
    edit_checklist_item: 'Checklisten-Eintrag bearbeiten',
    order: 'Reihenfolge',
    checklist_name_empty: 'Bitte benennen Sie den neuen Eintrag',
    checklist_status_empty: 'Bitte wählen Sie den Status des Eintrags',
    checklist_item_created: 'Neuer Checklisten-Eintrag wurde erstellt',
    checklist_item_updated: 'Checklisten-Eintrag wurde aktualisiert',

    new_dokumentvorlagen_item: 'Neue Dokumentenvorlage',
    dokumentvorlagen_name_empty: 'Bitte benennen Sie die Dokumentenvorlage',
    dokumentvorlagen_desc_empty: 'Bitte beschreiben Sie die Dokumentenvorlage',
    dokumentvorlagen_file_empty: 'Bitte wählen Sie eine Datei aus',
    dokumentvorlagen_item_created: 'Neue Dokumentenvorlage wurde erstellt',

    fav_icon: 'Favicon',
    white_logo: 'Logo für weißen Hintergrund',
    dark_logo: 'Logo für dunklen Hintergrund',
    icons_settings: 'Einstellungen für Website-Symbole',

    enter_otp_to_disable: 'Geben Sie die pushTan ein, um die 2-Faktor-Authentisierung auszuschalten',
    enter_otp_to_enable: 'Geben Sie die pushTan ein, um die 2-Faktor-Authentisierung zu aktivieren',
    two_factor_auth: 'Zwei-Faktor-Authentifizierung',
    secret_code: 'Secret Code',
    row_per_page: 'Zeilen pro Seite'
}
