import axios from 'axios'

export default {

    async adminGetTickets({commit}, page) {
        return new Promise((resolve, reject) => {
            axios({url: `/admin/tickets?page=${page}`, method: 'GET'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminGetTicket({commit}, id) {
        return new Promise((resolve, reject) => {
            axios({url: `/admin/tickets/${id}`, method: 'GET'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminUpdateStatusTicket({commit}, data) {
        return new Promise((resolve, reject) => {
            axios({url: `/admin/tickets/${data.id}/status`, data: data, method: 'PUT'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminSendTicketComment({commit}, data) {
        return new Promise((resolve, reject) => {
            axios({url: `/admin/tickets/${data.ticket_id}/comments`, data: data, method: 'POST'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}
