import axios from 'axios'

export default {
    async addCompetitionParticipant({commit}, data) {
        return new Promise((resolve, reject) => {
            axios({url: `/competition-participant`, data: data, method: 'POST'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    }
}
