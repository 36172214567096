<template>
  <v-container>
    <v-row>
      <v-col
          v-for="(social, index) in socialLinks"
          :key="index"
          cols="3"
          class="d-flex align-center justify-center"
      >
        <v-hover v-slot="{ hover }">
          <v-card
              height="100%"
              :elevation="hover ? 7 : 5"
              :href="social.url"
              target="_blank"
              class="rounded-circle transparent"
          >
            <v-img
                :src="getImageSrc(social.image)"
                :alt="social.alt"
                :max-width="iconSize"
            />
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import store from "@/store";

export default {
  props: {
    iconSize: {
      type: String,
      default: '47',
    },
    iconColor: {
      type: String,
      default: 'secondary',
      validator(value) {
        return ['white', 'primary', 'secondary'].includes(value);
      },
    },
  },
  methods: {
    getImageSrc(image) {
      const suffix = ['white', 'primary', 'secondary'].includes(this.iconColor)
          ? this.iconColor
          : 'secondary';
      return image.replace('secondary', suffix);
    },
  },
  data() {
    return {
      socialLinks: [
        {
          url: "https://www.facebook.com/Geras24online/",
          image: `${store.getters.frontUrl}/assets/img/icons/facebook-secondary.svg`,
          alt: "Facebook",
        },
        {
          url: "https://www.instagram.com/geras24.de/",
          image: `${store.getters.frontUrl}/assets/img/icons/instagram-secondary.svg`,
          alt: "Instagram",
        },
        {
          url: "https://www.youtube.com/c/Geras24",
          image: `${store.getters.frontUrl}/assets/img/icons/youtube-secondary.svg`,
          alt: "YouTube",
        },
        {
          url: "https://www.linkedin.com/in/torsten-te-pa%C3%9F-a671961bb/",
          image: `${store.getters.frontUrl}/assets/img/icons/linkedin-secondary.svg`,
          alt: "LinkedIn",
        },
      ],
    };
  },
};
</script>
