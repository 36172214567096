<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        persistent
        max-width="600"
        justify="center"
    >
      <v-card class="text-center" id="cookies">
        <div v-if="!details">
          <v-card-title class="text-h5 justify-center mb-3">
            Privatsphäre-Einstellungen
          </v-card-title>
          <v-card-text class="black--text">
            Wir verwenden Cookies und ähnliche Technologien auf unserer Website und verarbeiten personenbezogene Daten über Dich, wie Deine IP-Adresse. Wir teilen diese Daten auch mit Dritten. Die Datenverarbeitung kann mit Deiner Einwilligung oder auf Basis eines berechtigten Interesses erfolgen, dem Du in den individuellen Datenschutzeinstellungen widersprechen kannst. Mit Betätigen des Buttons „Ablehnen“ werden ausschließlich essenzielle, für den Betrieb der Webseite unbedingt erforderliche Cookies gesetzt. Einmal erteilte Einwilligungen kannst Du jederzeit widerrufen.
          </v-card-text>
        </div>
        <div v-else>
          <v-card-title class="text-h5 justify-center mb-3">
            Individuelle Privatsphäre-Einstellungen
          </v-card-title>
          <v-card-text>
            Einige Services verarbeiten personenbezogene Daten in den USA. Indem Du der Nutzung dieser Services zustimmst, erkärst Du dich auch mit der Verarbeitung Deiner Daten in den USA gemäß Art. 49 (1) lit. a DSGVO einverstanden. Die USA werden vom EuGH als ein Land mit einem unzureichenden Datenschutzniveau nach EU-Standards angesehen. Insbesondere besteht das Risiko, dass Deine Daten von US-Behörden zu Kontroll- und Überwachungszwecken verarbeitet werden, unter Umständen ohne die Möglichkeit eines Rechtsbehelfs.
            <br><br>
            Du bist unter 16 Jahre alt? Dann kannst Du nicht in optionale Services einwilligen, oder Du kannst Deine Eltern oder Erziehungsberechtigten bitten, mit Dir in diese Services einzuwilligen.

            <v-expansion-panels class="mt-5">

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <v-row no-gutters>
                    <div class="col my-auto font-weight-medium">
                      Google Analytics
                    </div>
                    <div class="col-auto">
                      <v-switch
                          v-model="checks.analytics"
                          @click.native.stop
                          hide-details
                          style="margin-top: 0; margin-right: 20px;"
                          color="lightGreen"
                      ></v-switch>
                    </div>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <tbody class="cookies">
                      <tr>
                        <td>Cookie</td>
                        <td>_ga</td>
                      </tr>
                      <tr>
                        <td>Anbieter</td>
                        <td>Google</td>
                      </tr>
                      <tr>
                        <td>Zweck</td>
                        <td>Von Google genutztes Tracking-Cookie, das von uns für die statistische Auswertung eingesetzt wird</td>
                      </tr>
                      <tr>
                        <td>Laufzeit</td>
                        <td>2 Jahre</td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <v-simple-table dense class="mt-4">
                    <template v-slot:default>
                      <tbody class="cookies">
                      <tr>
                        <td>Cookie</td>
                        <td>_ga_X2P95EQT37</td>
                      </tr>
                      <tr>
                        <td>Anbieter</td>
                        <td>Google</td>
                      </tr>
                      <tr>
                        <td>Zweck</td>
                        <td>Von Google genutztes Tracking-Cookie, das von uns für die statistische Auswertung eingesetzt wird</td>
                      </tr>
                      <tr>
                        <td>Laufzeit</td>
                        <td>2 Jahre</td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <v-row no-gutters>
                    <div class="col my-auto font-weight-medium">
                      Facebook
                    </div>
                    <div class="col-auto">
                      <v-switch
                          v-model="checks.facebook"
                          @click.native.stop
                          hide-details
                          style="margin-top: 0; margin-right: 20px;"
                          color="lightGreen"
                      ></v-switch>
                    </div>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <tbody class="cookies">
                      <tr>
                        <td>Cookie</td>
                        <td>_fbp</td>
                      </tr>
                      <tr>
                        <td>Anbieter</td>
                        <td>Facebook</td>
                      </tr>
                      <tr>
                        <td>Zweck</td>
                        <td>Von Facebook genutztes Tracking-Cookie, das von uns zu Marketingzwecken eingesetzt wird</td>
                      </tr>
                      <tr>
                        <td>Laufzeit</td>
                        <td>90 Tage</td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <v-simple-table dense class="mt-4">
                    <template v-slot:default>
                      <tbody class="cookies">
                      <tr>
                        <td>Cookie</td>
                        <td>datr</td>
                      </tr>
                      <tr>
                        <td>Anbieter</td>
                        <td>Facebook</td>
                      </tr>
                      <tr>
                        <td>Zweck</td>
                        <td>Von Facebook genutztes Tracking-Cookie, das von uns zu Marketingzwecken eingesetzt wird</td>
                      </tr>
                      <tr>
                        <td>Laufzeit</td>
                        <td>2 Jahre</td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <v-simple-table dense class="mt-4">
                    <template v-slot:default>
                      <tbody class="cookies">
                      <tr>
                        <td>Cookie</td>
                        <td>oo</td>
                      </tr>
                      <tr>
                        <td>Anbieter</td>
                        <td>Facebook</td>
                      </tr>
                      <tr>
                        <td>Zweck</td>
                        <td>Von Facebook genutztes Tracking-Cookie, das von uns zu Marketingzwecken eingesetzt wird</td>
                      </tr>
                      <tr>
                        <td>Laufzeit</td>
                        <td>5 Jahre</td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-expansion-panel-content>
              </v-expansion-panel>

            </v-expansion-panels>
          </v-card-text>
        </div>
        <v-card-actions class="justify-center">
<!--          <div>-->
            <v-btn
                v-if="details"
                class="btn-grey btn-cookie btn-save"
                text
                @click="save()"
            >
              Speichern
            </v-btn>
            <v-btn
                class="btn-grey btn-cookie btn-decline"
                text
                @click="decline()"
            >
              Ablehnen
            </v-btn>
            <v-btn
                class="btn-default btn-cookie btn-accept"
                text
                @click="saveAll()"
            >
              <div v-if="details">Alle akzeptieren und schließen</div> <div v-else>Akzeptieren und schließen</div>
            </v-btn>
<!--          </div>-->
        </v-card-actions>

        <div class="py-3">
          <a href="/datenschutz" class="cookie-links">Datenschutzerklärung</a> <a href="/impressum" class="cookie-links px-3">Impressum</a> <a href="#" class="cookie-links" @click.prevent="toggleDetails()"><span v-if="!details">Mehr Informationen</span><span v-else>Weniger Informationen</span></a>
        </div>

      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

  import Cookies from 'js-cookie'
  import $ from 'jquery'

  export default {
    data () {
      return {
        dialog: false,
        details: false,
        checks: {
          analytics: false,
          facebook: false
        }
      }
    },
    props:{
    },
    mounted() {
      this.checkConsentCookie()
    },
    methods: {
      checkConsentCookie() {
        if(this.$route.name !== 'datenschutz' && this.$route.name !== 'impressum') {
          if(!Cookies.get('COOKIE_CONSENT')) {
            this.dialog = true
          } else {
            this.readCookieAndLoadScripts()
          }
        }
      },
      decline() {
        this.checks.analytics = false
        this.checks.facebook = false
        this.save()
      },
      saveAll() {
        this.checks.analytics = true
        this.checks.facebook = true
        this.save()
      },
      save() {
        Cookies.set('COOKIE_CONSENT', this.checks, { expires: 365 })
        this.dialog = false

        this.readCookieAndLoadScripts()
      },
      readCookieAndLoadScripts() {
        var consent = JSON.parse(Cookies.get('COOKIE_CONSENT'))

        if(consent.analytics) this.loadAnalytics()
        if(consent.facebook) this.loadFacebook()
      },
      loadAnalytics() {
        $.when(
            $.getScript('https://www.googletagmanager.com/gtag/js?id=G-X2P95EQT37'),
            $.getScript('assets/js/analytics.js'),
            $.Deferred(function( deferred ) {
              $( deferred.resolve );
            })
        ).done(function (s, Status) {
        }.bind(this));
      },
      loadFacebook() {
        $.when(
            $.getScript('assets/js/fb.js'),
            $.Deferred(function( deferred ) {
              $( deferred.resolve );
            })
        ).done(function (s, Status) {
        }.bind(this));
      },
      toggleDetails() {
        this.details = !this.details
      }
    }
  }
</script>