import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import alleato from "@/icons/alleato.vue";

Vue.use(Vuetify)

const theme = {
    primary: '#3e8a82',
    success: '#3e8a82',
    error: '#aa000a',
    secondary: '#9C27b0',
    accent: '#9C27b0',
    info: '#00CAE3',
    lightGreen: '#70b197',
}

export default new Vuetify({
    theme: {
        themes: {
            dark: theme,
            light: theme,
        },
    },
    icons: {
        values: {
            alleato: {
                component: alleato
            }
        }
    }
})
