import Vue from 'vue';
import VueI18n from 'vue-i18n';

export const langs = ['de'];

// Languages
import DE from '../lang/de/index'

Vue.use(VueI18n);

const messages = {
    'de': DE,
};

export const selectedLocale = 'de'


const i18n = new VueI18n({
    locale: selectedLocale,
    fallbackLocale: 'de',
    messages,
});

export default i18n