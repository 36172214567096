import axios from 'axios'

export default {

    async sendContactEmail({commit}, data) {
        return new Promise((resolve, reject) => {
            axios({url: 'send-contact-email', data: data, method: 'POST'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}
