import axios from 'axios'
import store from '../../index'

export default {

    async getMe({commit}) {
        return new Promise((resolve, reject) => {
            axios({
                url: `${store.getters.isLoggedIn ? (store.getters.user.user.role === 'Owner' ? '/me' : '/admin/me') : '/me'}`, method: 'GET'
            })
                .then(resp => {
                    commit('update_user', resp.data)
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async updateProfile({commit}, data) {
        return new Promise((resolve, reject) => {
            axios({url: `/dashboard/user/profile`, data: data, method: 'PUT'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async uploadAvatar({commit}, data) {
        return new Promise((resolve, reject) => {
            axios({url: `/dashboard/user/avatar`, data: data, method: 'POST'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async updatePassword({commit}, data) {
        return new Promise((resolve, reject) => {
            axios({url: `/dashboard/user/password`, data: data, method: 'POST'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async disableOtp({commit}, data) {
        return new Promise((resolve, reject) => {
            axios({url: `/otp/disable`, data: data, method: 'POST'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}
