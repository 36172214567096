<template>
  <v-row justify="center">
    <v-dialog
        :value="value"
        @input="$emit('input', $event)"
        persistent
        width="500"
    >
      <v-card>
        <v-card-title>
          <span>{{ $t('reset_password') }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="$emit('close-password-reset-modal')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="text--primary">
          <v-container>
            <v-row class="mb-6">
              {{ $t('reset_password_description') }}
            </v-row>
            <v-row>
              <v-text-field
                  :label="$t('email')"
                  :disabled="loading"
                  v-model="form.email"
                  outlined
                  @keyup.enter="reset"
              ></v-text-field>
            </v-row>
            <v-row>
              <v-btn
                  :loading="loading"
                  :disabled="loading"
                  color="primary"
                  block
                  large
                  @click="reset"
              >
                {{ $t('reset_password') }}
              </v-btn>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

export default {
  props: ['value'],
  data() {
    return {
      loading: false,
      form: {
        email: null,
      },
    }
  },
  methods: {
    reset() {
      if (!this.form.email) {
        this.$helpers.showMessage('error', this.$t('enter_email'))
        return
      }
      this.loading = true
      this.$store.dispatch('resetPassword', this.form)
          .then(() => {
            this.$helpers.showMessage('success', this.$t('reset_password_success'))
            this.form = {
              email: null
            }
            this.$router.push({name: 'home'})
            this.$emit('close-password-reset-modal')
            this.loading = false
          })
          .catch(err => {
            this.$helpers.showError(err)
            this.loading = false
          })
    },
  }
}

</script>
