import Vue from "vue";
import store from '../store/index'
import moment from 'moment'

export default {
    showError(err) {
        try {
            if (err.response.status === 422) {
                for (const key in err.response.data.errors) {
                    if (Object.hasOwnProperty.call(err.response.data.errors, key)) {
                        const error = err.response.data.errors[key];
                        Vue.$toast.error(error[0])
                    }
                }
            } else if (err.response.status === 403) {
                Vue.$toast.error('Zugriff verweigert!')
            } else {
                Vue.$toast.error(err.response.data.message || 'Fehler!')
            }
        } catch (error) {
            Vue.$toast.error('Error!')
        }
    },
    showMessage(type, msg) {
        if (type === 'success') {
            Vue.$toast.success(msg);
        } else if (type === 'error') {
            Vue.$toast.error(msg);
        } else if (type === 'warning') {
            Vue.$toast.warning(msg);
        } else if (type === 'info') {
            Vue.$toast.info(msg);
        } else {
            Vue.$toast.default(msg);
        }
    },
    checkPermission(permission) {
        for (let index = 0; index < store.getters.user.user.permissions.length; index++) {
            const per = store.getters.user.user.permissions[index];
            if (per.name === permission) return true
        }
        return false
    },
    formatDate(date, format = "DD.MM.YYYY HH:mm") {
        if (!moment(date).isValid()) {
            console.warn("Invalid date:", date);
            return date;
        }

        return moment(date).format(format);
    },
}
