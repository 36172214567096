<template>
  <v-container class="pa-0">
    <video-player
        class="video-player video-player-box vjs-big-play-centered"
        ref="videoPlayer"
        :options="playerOptions"
        preload="auto"
        :playsinline="true">
    </video-player>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      play: true,
      playerOptions: {
        muted: false,
        language: 'de',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [{
          src: this.src
        }],
        fluid: true,
        poster: this.cover
      }
    }
  },
  props: ['src', 'cover'],
  computed: {
    player() {
      return this.$refs.videoPlayer.player
    }
  },
};
</script>
